/**
 * Sezóna 2023/2024
 */
import React from 'react'
import { Link } from 'gatsby'

import { DefaultPageProps } from '../../types'
import Layout from '../../components/layout'

function EnrollmentAccepted({ location }: DefaultPageProps) {
  return (
    <Layout location={location} customClass="contacts">
      <header>
        <h1>Děkujeme za váš zájem.</h1>
      </header>
      <div className="page-content">
        <h3>Vaši přihlášku jsme přijali.</h3>
        <p>Potvrzení o přihlášení Vám bylo zasláno na e-mail.</p>
        <br />
        Pokračovat na <Link to="/">hlavní stranu</Link> nebo{' '}
        <Link to="/predzapis">zpět na zápis</Link>.
      </div>
    </Layout>
  )
}

export default EnrollmentAccepted
